import { onlyNumbers } from '@/filters/onlyNumbers';
import { withParams } from 'vuelidate/lib';

const cpfCnpj = withParams({ type: 'cpfCnpj' }, cpfCnpj => {
  return validateCpfCnpj(cpfCnpj);
});

export function validateCpfCnpj(cpfCnpj) {
  if (cpfCnpj == null) return true;
  if (cpfCnpj.length > 14) {
    cpfCnpj = onlyNumbers(cpfCnpj);
    if (cpfCnpj === '' || cpfCnpj === null || cpfCnpj === undefined)
      return true;

    if (cpfCnpj.length !== 14) {
      return false;
    }

    if (
      cpfCnpj === '00000000000000' ||
      cpfCnpj === '11111111111111' ||
      cpfCnpj === '22222222222222' ||
      cpfCnpj === '33333333333333' ||
      cpfCnpj === '44444444444444' ||
      cpfCnpj === '55555555555555' ||
      cpfCnpj === '66666666666666' ||
      cpfCnpj === '77777777777777' ||
      cpfCnpj === '88888888888888' ||
      cpfCnpj === '99999999999999'
    ) {
      return false;
    }

    var length = cpfCnpj.length - 2;
    var numbers = cpfCnpj.substring(0, length);
    var digits = cpfCnpj.substring(length);
    var sum = 0;
    var pos = length - 7;

    for (var i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    var resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (resultado != digits.charAt(0)) {
      return false;
    }

    length = length + 1;
    numbers = cpfCnpj.substring(0, length);
    sum = 0;
    pos = length - 7;
    for (i = length; i >= 1; i--) {
      sum += numbers.charAt(length - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (resultado != digits.charAt(1)) {
      return false;
    }

    return true;
  } else {
    // cpfCnpj = cpfCnpj.replace(/[^\d]+/g, '')
    cpfCnpj = onlyNumbers(cpfCnpj);
    if (cpfCnpj === '' || cpfCnpj === null || cpfCnpj === undefined)
      return true;

    if (cpfCnpj.length !== 11) {
      return false;
    }

    if (cpfCnpj === '') return false;
    // Elimina CPFCnpjs invalidos conhecidos
    if (
      cpfCnpj.length !== 11 ||
      cpfCnpj === '00000000000' ||
      cpfCnpj === '11111111111' ||
      cpfCnpj === '22222222222' ||
      cpfCnpj === '33333333333' ||
      cpfCnpj === '44444444444' ||
      cpfCnpj === '55555555555' ||
      cpfCnpj === '66666666666' ||
      cpfCnpj === '77777777777' ||
      cpfCnpj === '88888888888' ||
      cpfCnpj === '99999999999'
    ) {
      return false;
    }

    var add = 0;
    for (var i = 0; i < 9; i++) {
      add += parseInt(cpfCnpj.charAt(i)) * (10 - i);
    }
    var rev = 11 - (add % 11);

    if (rev === 10 || rev === 11) {
      rev = 0;
    }

    if (rev != parseInt(cpfCnpj.charAt(9))) {
      return false;
    }

    add = 0;
    for (i = 0; i < 10; i++) {
      add += parseInt(cpfCnpj.charAt(i)) * (11 - i);
    }
    rev = 11 - (add % 11);

    if (rev === 10 || rev === 11) {
      rev = 0;
    }

    if (rev != parseInt(cpfCnpj.charAt(10))) {
      return false;
    }

    return true;
  }
}

export { cpfCnpj };
