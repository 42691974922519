import axios from 'axios';

export default class PaymentAccountsApi {
  async requestTransferBetweenHolders(amount, holderTaxDocument, authCode) {
    const options = {
      headers: { authCode },
    };

    var model = {
      requests: [
        {
          amount,
          holderTaxDocument,
        },
      ],
    };

    var result = await axios.post(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_V2_URL}/cashout-request/between-holders`,
      model,
      options
    );

    return result;
  }

  async getPixKeyOwnerDetails(key) {
    return await axios.get(
      `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_V2_URL}/pix/${key}/details`
    );
  }
}
