import axios from 'axios';

class AuthCodeApi {
  async getAuthCode(parameters) {
    try {
      const model = parameters;
      const result = await axios.post(
        `${process.env.VUE_APP_PAYMENT_ACCOUNTS_API_V2_URL}/auth-code/`,
        model
      );
      return result;
    } catch (error) {
      return error;
    }
  }
}

export default AuthCodeApi;
