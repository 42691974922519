<template>
  <div
    class="modal fade"
    data-backdrop="static"
    tabindex="-1"
    role="dialog"
    :id="id"
  >
    <div class="modal-dialog modal-base " :class="[size, centered ? 'modal-dialog modal-dialog-centered' : '']">
      <div class="modal-content">
        <div class="modal-header" v-if="hasTitle === true">
          <h5 class="modal-title">{{ title }}</h5>
          <button
            type="button"
            class="close button-close"
            aria-label="Close"
            @click="toggle()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div
          class="modal-body"
          :class="{ 'modal-body-whitout-top': btnBackgroundGray }"
        >
          <div
            class="btn-close mt-4"
            :class="{
              'btn-close-background-gray': btnBackgroundGray,
              noHeader: noHeader,
            }"
          >
            <button
              v-if="hasTitle === false"
              type="button"
              class="close button-close pt-3"
              @click="toggle()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <slot name="content"></slot>
        </div>
        <div class="modal-footer" v-if="hasFooter === true">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalBase',
  props: {
    title: {
      type: String,
      default: null,
    },
    hasFooter: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: 'openModal',
    },
    btnBackgroundGray: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'modal-sm',
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggle() {
      /* eslint-disable no-undef */
      $(`#${this.id}`).modal('toggle');
      this.$bus.$emit('cleanInput');
      this.$bus.$emit('closePreferences');
    },
  },
  computed: {
    hasTitle: function () {
      return this.$props.title !== null && this.$props.title !== undefined;
    },
  },
};
</script>

<style scoped lang="scss">
.modal-dialog {
  height: -webkit-fill-available;
}

.modal-header {
  padding: 1.5rem;
  border-bottom: unset;
}

.btn-close-background-gray {
  background-color: #f5f5f5;
  padding-top: 0.5rem;
}

.button-close {
  font-size: 2.5rem;
  line-height: 0.4;
  font-weight: lighter;
  outline: 0;
}

.modal-body-whitout-top {
  padding-top: 0px;
}
.modal-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1.7rem;
  line-height: 2rem;
  color: #8e8e8e;
}

.modal-content {
  border-radius: unset;
}

.modal-body {
  padding: 0;
}

.btn-close {
  display: flex;
  justify-content: flex-end;
  padding-right: 1.5rem;

  .close {
    display: block;
  }
}
@media (min-width: 768px) {
  .details-transference-size {
    max-width: calc(100% - 16.5rem);
    padding: 0 10px;
  }
  .preferences-account-size {
    max-width: 400px;
  }
  .how-much-transfer {
    max-width: 400px;
  }
  .transference-size {
    max-width: 400px;
  }
  .modal-content {
    margin: 10px;
    margin-left: 8.2rem;
  }
}
@media (min-width: 992px) {
  .btn-close.noHeader {
    display: none;
  }
  .modal-max-content {
    margin: 10px !important;
    margin-left: calc(16.5rem + 10px) !important;
    max-width: 100%;
    max-height: 100%;
  }
}
@media (max-width: 991px) {
  .btn-close.noHeader {
    padding-top: 0.7rem;
  }
}
</style>
