import CustomApiError from './CustomError';

export default class AuthCodeError extends CustomApiError {
  toDictionary() {
    return {
      AUTH_CODE_NOT_FOUND: 'Código inválido.',
      OPERATING_HOURS_INVALID: 'O horário não permite mais esta operação.',
      PAYMENT_ACCOUNT_DESTINATION_NOT_FOUND:
        'Conta de pagamento destino não encontrada.',
      PAYMENT_ACCOUNT_NOT_FOUND: 'Conta de pagamento não encontraca.',
      NOTIFICATION_FAILED:
        'Falha ao gerar código de confirmação. Tente novamente.',
      UNAUTHORIZED_DEVICE: 'Dispositivo não autorizado.',
    };
  }
}
